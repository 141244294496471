import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import {
  AuditoriaCsvParams,
  AuditoriaResponse,
  UltimosLogins,
} from "./auditoria.model";
import { convertObjectToHttpParams } from "../../core/utils/convertObjectToHttpParams";

@Injectable({
  providedIn: "root",
})
export class AuditoriaService {
  http = inject(HttpClient);
  auditoriaUrl = `${environment.apiUrl}auditoria`;

  getAuditoria(params: HttpParams): Observable<AuditoriaResponse> {
    return this.http.get<AuditoriaResponse>(`${this.auditoriaUrl}?`, {
      params,
    });
  }

  getAuditoriaCsv(params: AuditoriaCsvParams): Observable<string> {
    return this.http.get<string>(`${this.auditoriaUrl}/csv`, {
      params,
      responseType: "text" as "json",
    });
  }

  getUltimosLogins(): Observable<UltimosLogins[]> {
    return this.http.get<UltimosLogins[]>(`${this.auditoriaUrl}/cpf/`);
  }

  sendByMail(params: AuditoriaCsvParams): Observable<void> {
    return this.http.get<void>(
      `${this.auditoriaUrl}/envia?${convertObjectToHttpParams(params)}`,
    );
  }
}
